import { useMutation, useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import coursesAPIs from '../../api/courses';
import sectionsAPIs from '../../api/section';
import Pagination from '../../components/Pagination';
import TitlePage from '../../components/TitlePage';
import H1Styled from '../../components/Typography/H1Styled';
import H3Styled from '../../components/Typography/H3Styled';
import { useAuth } from '../../contexts/Auth';
import { isSegreteriaNazionale } from '../../libs/utils/auth';
import StatisticsCourse from './Tables/StatisticsCourse';
import StatisticsEvent from './Tables/StatisticsEvents';
import statisticsAPIs from '../../api/statisticsAPIs';
import moment from 'moment';
import 'moment/locale/it';
import { useEffect, useMemo } from 'react';
import FiltersBar from '../../components/Filters/FiltersBar';
import { FilterType } from '../../interfaces/filters';
import eventsAPIs from '../../api/events';
import { EventStatus } from '../../interfaces/events';
import QueryString from 'qs';
import { ProductType } from '../../interfaces/orders';
import { getDateTimeFromDate } from '../../libs/utils/helpers';
import GeneralStatistics from './GeneralStatistics';
import PrimaryButton from '../../components/Buttons/PrimaryButton';
import { toast } from 'react-toastify';
import { DownloadIcon } from '@heroicons/react/outline';

moment.locale('it');

const { REACT_APP_DEBOUNCE_DELAY } = process.env;
const delay = Number(REACT_APP_DEBOUNCE_DELAY);

export default function Statistiche() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [{ token, profile }] = useAuth();

  const coursePagination = {
    pageSize: `${ProductType.COURSE}PageSize`,
    page: `${ProductType.COURSE}Page`,
  };

  const eventPagination = {
    pageSize: `${ProductType.EVENT}PageSize`,
    page: `${ProductType.EVENT}Page`,
  };

  const [debounceSearchParams] = useDebounce(searchParams, delay);

  const coursesSearchQuery = useMemo(
    () => getStatisticsSearchQuery(searchParams, ProductType.COURSE),
    [searchParams]
  );

  const eventsSearchQuery = useMemo(
    () => getStatisticsSearchQuery(searchParams, ProductType.EVENT),
    [searchParams]
  );

  useEffect(() => {
    searchParams.set(coursePagination.pageSize, '10');
    searchParams.set(eventPagination.pageSize, '10');
    searchParams.set('year', '2024');
    setSearchParams(searchParams);
  }, []);

  const statisticsQuery = useQuery(
    ['findStatistics', searchParams.get('year')],
    () =>
      statisticsAPIs.find({
        token,
        query: {
          year: searchParams.get('year'),
        },
      })
  );

  const courseQuery = useQuery(
    [
      'coursesStatistics',
      [
        debounceSearchParams.get('year'),
        debounceSearchParams.get(`${ProductType.COURSE}Sections`),
        debounceSearchParams.get(coursePagination.page),
        debounceSearchParams.get(coursePagination.pageSize),
        debounceSearchParams.get(`${ProductType.COURSE}Date`),
      ],
    ],
    () =>
      coursesAPIs.find({
        query: coursesSearchQuery,
        token,
      })
  );

  const eventsQuery = useQuery(
    [
      'eventsStatistics',
      [
        debounceSearchParams.get('year'),
        debounceSearchParams.get(`${ProductType.EVENT}Sections`),
        debounceSearchParams.get(eventPagination.page),
        debounceSearchParams.get(eventPagination.pageSize),
        debounceSearchParams.get(`${ProductType.EVENT}Date`),
      ],
    ],
    () =>
      eventsAPIs.find({
        query: getStatisticsSearchQuery(
          debounceSearchParams,
          ProductType.EVENT
        ),
        token,
      })
  );

  const { mutate: courseStatsCSVMutation, isLoading: isDownloadingCoursesCSV } =
    useMutation('downloadCoursesCSVStats', coursesAPIs.downloadStatsCSV, {
      onError: () => {
        toast.error('Ooops... Qualcosa è andato storto.');
      },
      onSuccess: () => {
        if (Number(courseQuery.data?.meta?.pagination?.total) >= 30000)
          toast.warning(
            "Documento CSV scaricato con successo.\nL'export csv è stato limitato a 30000 elementi."
          );
        else toast.success('Documento CSV scaricato con successo');
      },
    });

  const { mutate: eventStatsCSVMutation, isLoading: isDownloadingEventsCS } =
    useMutation('downloadEventsCSVStats', eventsAPIs.downloadStatsCSV, {
      onError: () => {
        toast.error('Ooops... Qualcosa è andato storto.');
      },
      onSuccess: () => {
        if (Number(courseQuery.data?.meta?.pagination?.total) >= 30000)
          toast.warning(
            "Documento CSV scaricato con successo.\nL'export csv è stato limitato a 30000 elementi."
          );
        else toast.success('Documento CSV scaricato con successo');
      },
    });

  return (
    <div>
      <TitlePage title='Home Dashboard' />
      <div className='py-10 px-4 lg:px-10'>
        <H1Styled>Statistiche per corsi ed eventi</H1Styled>
        <p className='text-gray-500'>
          Statistiche con indicatori totali per anno e per sezione
        </p>
      </div>

      <div className='pb-7 lg:pb-12 px-4 lg:px-10 w-full space-y-10'>
        <div className='space-y-5'>
          <FiltersBar
            hideAppliedFilters
            filters={[
              {
                type: FilterType.RADIO,
                attribute: 'year',
                label: 'Anno',
                source: arrayRange(2023, new Date().getFullYear(), 1)
                  .reverse()
                  .map((year) => ({
                    value: year.toString(),
                    label: year.toString(),
                  })),
              },
            ]}
          />
          <GeneralStatistics statisticsQuery={statisticsQuery} />
        </div>

        <div className='space-y-5'>
          <H3Styled>
            Preventivo e consuntivo <span className='font-medium'>Corsi</span>
          </H3Styled>
          {isSegreteriaNazionale(profile) && (
            <FiltersBar
              filters={[
                {
                  type: FilterType.MULTISELECT,
                  attribute: `${ProductType.COURSE}Sections`,
                  label: 'Sezione',
                  key: 'sectionsListFilters',
                  source: (data: any) =>
                    sectionsAPIs.findOptions({
                      ...data,
                      query: { ...data.query },
                    }),
                  searchForAttributes: ['name'],
                },
                {
                  type: FilterType.DATE,
                  label: 'Data',
                  attribute: `${ProductType.COURSE}Date`,
                },
              ]}
            />
          )}
          <div className='flex justify-end w-full'>
            <PrimaryButton
              textSmall
              disabled={isDownloadingCoursesCSV}
              isLoading={isDownloadingCoursesCSV}
              onClick={() => {
                courseStatsCSVMutation({
                  token,
                  query: coursesSearchQuery,
                  fileName: `Statistiche Corsi ONAV ${new Date().getTime()}`,
                });
              }}
            >
              <span className='text-sm underline flex flex-row'>
                <DownloadIcon className='w-4 h-4 mt-[3px]' /> <p>Scarica CSV</p>
              </span>
            </PrimaryButton>
          </div>
          <StatisticsCourse query={courseQuery} />
          <Pagination
            pagination={courseQuery.data?.meta.pagination}
            pageAttrName={coursePagination.page}
            pageSizeAttrName={coursePagination.pageSize}
          />
        </div>

        <div className='space-y-5'>
          <H3Styled>
            Preventivo e consuntivo <span className='font-medium'>Eventi</span>
          </H3Styled>
          {isSegreteriaNazionale(profile) && (
            <FiltersBar
              filters={[
                {
                  type: FilterType.MULTISELECT,
                  attribute: `${ProductType.EVENT}Sections`,
                  label: 'Sezione',
                  key: 'sectionsListFilters',
                  source: (data: any) =>
                    sectionsAPIs.findOptions({
                      ...data,
                      query: { ...data.query },
                    }),
                  searchForAttributes: ['name'],
                },
                {
                  type: FilterType.DATE,
                  label: 'Data',
                  attribute: `${ProductType.EVENT}Date`,
                },
              ]}
            />
          )}
          <div className='flex justify-end w-full'>
            <PrimaryButton
              textSmall
              disabled={isDownloadingEventsCS}
              isLoading={isDownloadingEventsCS}
              onClick={() => {
                eventStatsCSVMutation({
                  token,
                  query: coursesSearchQuery,
                  fileName: `Statistiche Eventi ONAV ${new Date().getTime()}`,
                });
              }}
            >
              <span className='text-sm underline flex flex-row'>
                <DownloadIcon className='w-4 h-4 mt-[3px]' /> <p>Scarica CSV</p>
              </span>
            </PrimaryButton>
          </div>
          <StatisticsEvent query={eventsQuery} />
          <Pagination
            pagination={eventsQuery.data?.meta.pagination}
            pageAttrName={eventPagination.page}
            pageSizeAttrName={eventPagination.pageSize}
          />
        </div>
      </div>
    </div>
  );
}

const getStatisticsSearchQuery = (
  searchParams: URLSearchParams,
  productType: ProductType
) => {
  const currentYear =
    Number(searchParams.get('year')) || new Date().getFullYear();

  let startDateQuery: any = {
    $gte: new Date(currentYear, 0, 1),
    $lte:
      currentYear === new Date().getFullYear()
        ? new Date()
        : new Date(currentYear, 11, 31),
  };

  if (searchParams.get(`${productType}Date`)) {
    startDateQuery = {
      $gt: QueryString.parse(
        Object.entries(
          QueryString.parse(searchParams.get(`${productType}Date`) + '')
        ).map(([, value]) => value as any)[0]?.label
      ).from,
      $lt: getDateTimeFromDate(
        QueryString.parse(
          Object.entries(
            QueryString.parse(searchParams.get(`${productType}Date`) + '')
          ).map(([, value]) => value as any)[0]?.label
        ).to
      ),
    };
  }

  return {
    populate: [
      '*',
      'partecipations.guests',
      'section',
      'balance.price',
      'balance.contributes',
      'balance.wines',
      'balance.food',
      'balance.rooms',
      'balance.sponsorship',
      'balance.contributes',
      'balance.extras',
      'balance.teacherPayments',
      'balance.couponsExpenses',
      'balance.couponsVirtuosity',
      'balance.discountExpenses',
      'balance.teacherRefunds',
      'balance.direction',
      'headquarterBalance.cards',
      'headquarterBalance.administrations',
      'headquarterBalance.teachingMaterial',
      'headquarterBalance.shipping',
      'headquarterBalance.safetyMargin',
      'partecipations',
    ],
    sort: 'startDate:desc',

    pagination: {
      page: searchParams.get(`${productType}Page`),
      pageSize: searchParams.get(`${productType}PageSize`),
    },
    filters: {
      section: {
        id: {
          $in: Object.entries(
            QueryString.parse(searchParams.get(`${productType}Sections`) + '')
          )
            .map(([, value]) => value)
            .map((elem: any) => elem.value),
        },
      },
      status: {
        $notNull: true,
        $in: [
          EventStatus.PUBLISHED,
          EventStatus.FINAL_BALANCE_DELEGATION,
          EventStatus.FINAL_BALANCE_NATIONAL,
        ],
      },

      startDate: startDateQuery,
    },
  };
};

const arrayRange = (start: number, stop: number, step: number) =>
  Array.from(
    { length: (stop - start) / step + 1 },
    (_, index) => start + index * step
  );
