import { useMutation, useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDebounce } from 'use-debounce';
import coursesAPIs from '../../api/courses';
import { deleteCourse } from '../../api/courses';
import Pagination from '../../components/Pagination';
import TitlePage from '../../components/TitlePage';
import H1Styled from '../../components/Typography/H1Styled';
import { useAuth } from '../../contexts/Auth';
import CoursesTable from './Tables/CoursesTable';
import sectionsAPIs from '../../api/section';
import { FilterType } from '../../interfaces/filters';
import FiltersBar from '../../components/Filters/FiltersBar';
import { CourseStatus } from '../../interfaces/commons';
import qs from 'qs';
import { getDateTimeFromDate } from '../../libs/utils/helpers';
import { isDelegato } from '../../libs/utils/auth';
import PrimaryButton from '../../components/Buttons/PrimaryButton';
import { DownloadIcon } from '@heroicons/react/outline';
import { useState } from 'react';
import moment from 'moment';

const { REACT_APP_DEBOUNCE_DELAY } = process.env;
const delay = Number(REACT_APP_DEBOUNCE_DELAY);

export default function ListaCorsi() {
  const [searchParams] = useSearchParams();
  const [{ token, profile }] = useAuth();
  const [debounceSearchParams] = useDebounce(searchParams, delay);
  const [courseListFilters, setcourseListFilters] = useState<Object>();

  const query = useQuery(['courses', ...debounceSearchParams], () => {
    const query = {
      filters: {
        title: { $contains: debounceSearchParams.get('search') },
        section: {
          id: {
            $in: Object.entries(
              qs.parse(debounceSearchParams.get('sections') + '')
            )
              .map(([, value]) => value)
              .map((elem: any) => elem.value),
          },
        },
        startDate: { $contains: debounceSearchParams.getAll('year') },
        $and: [
          {
            startDate: {
              $gte: qs.parse(
                Object.entries(
                  qs.parse(debounceSearchParams.get('date') + '')
                ).map(([, value]) => value as any)[0]?.label
              ).from,
              $lte: getDateTimeFromDate(
                qs.parse(
                  Object.entries(
                    qs.parse(debounceSearchParams.get('date') + '')
                  ).map(([, value]) => value as any)[0]?.label
                ).to
              ),
            },
          },
          {
            status: {
              $in: Object.entries(
                qs.parse(debounceSearchParams.get('status') + '')
              )
                .map(([, value]) => value)
                .map((elem: any) => elem.value),
            },
          },
        ],
      },
      populate: [
        '*',
        'balance.price',
        'balance.contributes',
        'balance.wines',
        'balance.food',
        'balance.rooms',
        'balance.sponsorship',
        'balance.contributes',
        'balance.extras',
        'balance.teacherPayments',
        'balance.couponsExpenses',
        'balance.couponsVirtuosity',
        'balance.discountExpenses',
        'balance.teacherRefunds',
        'balance.direction',
        'headquarterBalance.cards',
        'headquarterBalance.administrations',
        'headquarterBalance.teachingMaterial',
        'headquarterBalance.shipping',
        'headquarterBalance.safetyMargin',
        'partecipations',
      ],
      pagination: {
        page: searchParams.get('page'),
        pageSize: searchParams.get('pageSize'),
      },
      sort: 'createdAt:desc',
    };
    setcourseListFilters(query);
    return coursesAPIs.find({
      token,
      query,
    });
  });

  const { mutate: deleteCourseMutation } = useMutation({
    mutationFn: (id: string | number) => deleteCourse(id, token),
    onSuccess: () => {
      toast.success('Corso eliminato con successo');
      query.refetch();
    },
    onError: () => {
      toast.error('Qualcosa è andato storto!');
    },
  });

  const { mutate: courseListCSVMutation, isLoading: isDownloadingCSV } =
    useMutation('downloadCoursesCSVList', coursesAPIs.downloadCoursesCSV, {
      onError: () => {
        toast.error('Ooops... Qualcosa è andato storto.');
      },
      onSuccess: () => {
        if (Number(query.data?.meta?.pagination?.total) >= 30000)
          toast.warning(
            "Documento CSV scaricato con successo.\nL'export csv è stato limitato a 30000 elementi."
          );
        else toast.success('Documento CSV scaricato con successo');
      },
    });
  return (
    <>
      <TitlePage title='Corsi' />
      <H1Styled>Tutti i corsi</H1Styled>

      <div className='space-y-5'>
        <FiltersBar
          filters={[
            {
              type: FilterType.DATE,
              attribute: 'date',
              label: 'Data',
              key: 'data',
            },
            {
              type: FilterType.MULTISELECT,
              attribute: 'sections',
              label: 'Sezione',
              key: 'sectionsListFilters',
              source: (data: any) => {
                return sectionsAPIs.findOptions({
                  ...data,
                  query: {
                    ...data.query,
                    ...(isDelegato(profile)
                      ? { filters: { id: profile?.section.data?.id } }
                      : {}),
                  },
                });
              },
              searchForAttributes: ['name'],
            },
            {
              type: FilterType.MULTISELECT,
              attribute: 'status',
              label: 'Stato',
              key: 'courseStatus',
              source: () => ({
                data: [
                  { id: CourseStatus.DRAFT, attributes: { name: 'Bozza' } },
                  {
                    id: CourseStatus.TO_APPROVE,
                    attributes: { name: 'In approvazione' },
                  },
                  {
                    id: CourseStatus.NOT_APPROVED,
                    attributes: { name: 'Non approvato' },
                  },
                  {
                    id: CourseStatus.PUBLISHED,
                    attributes: { name: 'Pubblicato' },
                  },
                  {
                    id: CourseStatus.FINAL_BALANCE_DELEGATION,
                    attributes: { name: 'Consuntivato da sezione' },
                  },
                  {
                    id: CourseStatus.FINAL_BALANCE_NATIONAL,
                    attributes: {
                      name: 'Consuntivato da segreteria nazionale',
                    },
                  },
                  {
                    id: CourseStatus.CANCELED,
                    attributes: { name: 'Annullato' },
                  },
                ],
              }),
            },
            {
              type: FilterType.SEARCH_BAR,
              attribute: 'search',
              label: 'Cerca...',
            },
          ]}
        />
        <div className='flex justify-end w-full'>
          <PrimaryButton
            textSmall
            disabled={isDownloadingCSV}
            isLoading={isDownloadingCSV}
            onClick={() => {
              courseListCSVMutation({
                token,
                query: courseListFilters,
                fileName: `Lista Corsi ONAV ${moment().format('DD-MM-YY')}`,
              });
            }}
          >
            <span className='text-sm underline flex flex-row'>
              <DownloadIcon className='w-4 h-4 mt-[3px]' /> <p>Scarica CSV</p>
            </span>
          </PrimaryButton>
        </div>
        <CoursesTable query={query} deleteCourse={deleteCourseMutation} />
        <Pagination pagination={query.data?.meta.pagination} />
      </div>
    </>
  );
}
